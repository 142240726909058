import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer-area'>
      <div><p>Webbsidan skapad av Joachim Karlsson <a href="https://www.instagram.com/trumpet_joche/"><i class="fa-brands fa-instagram"></i></a></p>  </div>
    </div>
  )
}

export default Footer