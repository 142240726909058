import React from 'react'
import TourList from './TourDatesCard.js'








const TourDates = () => {
  return (
    <div>
      <TourList />
    </div>
  );
}

export default TourDates